import { template as template_fe0384d5ccaa41dd947d6e077838c374 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fe0384d5ccaa41dd947d6e077838c374(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
