import { template as template_707902942e2c4491b1be0bf1f85f20b8 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
export default class DeferredRender extends Component {
    @tracked
    shouldRender = false;
    constructor(){
        super(...arguments);
        runAfterFramePaint(()=>(this.shouldRender = true));
    }
    static{
        template_707902942e2c4491b1be0bf1f85f20b8(`
    {{#if this.shouldRender}}
      {{yield}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
