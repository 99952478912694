import { template as template_c7ef2bd1af4b43a4b8c4336f138152e8 } from "@ember/template-compiler";
const FKLabel = template_c7ef2bd1af4b43a4b8c4336f138152e8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
