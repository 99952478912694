import { template as template_4fd20635c95d4f84b8d105a33623f31d } from "@ember/template-compiler";
const WelcomeHeader = template_4fd20635c95d4f84b8d105a33623f31d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
