import { template as template_9ec0569bfafc4529a8b98f716a195d7c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_9ec0569bfafc4529a8b98f716a195d7c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
