import { template as template_682586fc1ba4493e913133f9dc5498a9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_682586fc1ba4493e913133f9dc5498a9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
